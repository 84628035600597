import Vue from 'vue'
import router from './router'
import store from './store'
import 'font-awesome/css/font-awesome.min.css'
import globalComponents from "./assets/js/globalComponents.js"
import globalFilters from "./assets/js/globalFilters.js"
import { get, post, del } from '@/assets/js/http.js'
import Preview from '@/components/previewImage/index'
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 };
Vue.use(globalComponents)
Vue.use(globalFilters).use(Preview)
Vue.mixin({
	computed: {
		buttons() {
			return this.$store.state.buttons;
		},
		role() {
			return this.$store.state.type
		}
	},
	methods: {
		findButton(name) {
			return this.$store.state.buttons.find(item => item.title === name)
		},
		auth(auth) {
			if (typeof auth == number || typeof auth == 'string') {
				
				return this.$store.state.type == auth
			}
			if (typeof auth == 'object') {
				return auth.indexOf(Number(this.$store.state.type)) != -1
			}
		}
	}
})

import download from '@/components/download.vue'
Vue.component('zero-download', download)

Vue.config.productionTip = false
Vue.prototype.$get = get
Vue.prototype.$post = post
Vue.prototype.$del = del

// 获取当前路由下面的权限
import { getRouteAuth, auth } from '@/assets/js/auth'
Vue.prototype.$getRouteAuth = (path = router.currentRoute.path) => new getRouteAuth(path)
Vue.prototype.$auth = auth


Vue.prototype.$login = async () => {
	console.log(router.currentRoute.query,'query')
	console.log(window.location.href,'href')
	if (router.currentRoute.query.ticket) {
		store.dispatch('setType', Number(router.currentRoute.query.type || 1))	
		await post({
			url: "/admin/index/checktiket",
			data: {
				ticket: router.currentRoute.query.ticket
			}
		}).then(async res => {
			store.commit("login", res.userinfo);
		}).catch(err => {
			window.location.href = store.state.redirectUrl
		})
		const indexRes = await post({
			url: "/admin/index/index",
			loading: true,
			data: {
				type: Number(store.state.type || 1)
			}
		});
		store.dispatch("setAdminInfo", indexRes.adminInfo);
		store.dispatch('setMenu', indexRes.menus)
		location.href = '/'
		return true
	}else{
		return false
	}
}

import App from './App.vue'
import { number } from 'echarts'


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
