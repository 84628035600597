<template>
  <router-view></router-view>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  created() {

  },
  mounted() {
  },
  methods: {
    
  }
}
</script>
<style src="@/assets/css/element-m.css"></style>
<style src="@/assets/css/app.css"></style>
<style>
.el-message {
  z-index: 3002 !important;
}

.el-page-header__content {
  font-size: 15px;
}
</style>