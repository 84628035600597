import Vue from 'vue'
import Preview from './index.vue'

const PreviewConstructor = Vue.extend(Preview)
let PreviewDialog
export default {
    install: (app) => {
        app.prototype.$preview = (url)=>{
            PreviewDialog = new PreviewConstructor()
            PreviewDialog.$mount(document.createElement('div'))
            document.body.append(PreviewDialog.$el)
            PreviewDialog.url = url
            PreviewDialog.dialogVisible = true

            PreviewDialog.close=()=>{
                PreviewDialog.dialogVisible = false
                PreviewDialog.url = ''
                PreviewDialog.$el.remove()
                PreviewDialog.$destroy()
                PreviewDialog = null
            }
        }
    }
}