import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Index from "../views/index.vue";
import Login from "../views/login.vue";
import { Message } from "element-ui";

Vue.use(VueRouter);
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch((err) => err);
};

const routes = [
    {
        path: "/",
        name: "view-index",
        component: Index,
        children: [
            {
                path: "/",
                name: "view-dashboard",
                redirect: "/big-cate",
            },
            {
                path: "/dashboard",
                name: "view-dashboard",
                component: () =>
                    import(
                        "../views/dashboard/index.vue"
                        ),
            },
            {
                path: "/menu",
                name: "view-menu",
                component: () =>
                    import(
                        "../views/menu/index.vue"
                    ),
            },
            {
                path: "/admin",
                name: "view-admin",
                component: () =>
                    import(
                        "../views/admin/index.vue"
                    ),
            },
            {
                path: "/role",
                name: "view-role",
                component: () =>
                    import(
                        "../views/role/index.vue"
                    ),
            },
            //客户管理列表
            {
                path: "/member",
                name: "view-member",
                component: () =>
                    import("../views/member/index.vue"
                    ),
            },
            {
                path: "/admin-log",
                name: "view-admin-log",
                component: () =>
                    import(
                        "../views/admin-log/index.vue"
                    ),
            },
            {
                path: "/system-config",
                name: "view-system-config",
                component: () =>
                    import(
                        "../views/system-config/index.vue"
                    ),
            },
            {
                path: "/company",
                name: "company",
                component: () =>
                    import(
                        "../views/company/index.vue"
                    ),
            },
            {
                path: "/company/add",
                name: "company-add",
                component: () =>
                    import(
                        "../views/company/add.vue"
                    ),
            },
            {
                path: "/company-cate",
                name: "company-cate",
                component: () =>
                    import(
                        "../views/company-cate/index.vue"
                    ), 
            },
            {
                path: "/town",
                name: "town",
                component: () =>
                    import(
                        "../views/town/index.vue"
                    ), 
            },
            {
                path: "/group",
                name: "group",
                component: () =>
                    import(
                        "../views/group/index.vue"
                    ), 
            },
            {
                path: "/cadre",
                name: "cadre",
                component: () =>
                    import(
                        "../views/cadre/index.vue"
                    ), 
            },
            {
                path: "/supervisor",
                name: "supervisor",
                component: () =>
                    import(
                        "../views/supervisor/index.vue"
                    ), 
            },
            {
                path: "/appeal",
                name: "appeal",
                component: () =>
                    import(
                        "../views/appeal/index.vue"
                    ), 
            },{
                path:'/article',
                name:'article',
                component:()=>import('../views/article/index.vue')
            },
            {
                path:'/masses',
                name:'masses',
                component:()=>import('../views/masses/index.vue')
            },
            {
                path:'/complain',
                name:'complain',
                component:()=>import('../views/complain/index.vue')
            },
            {
                path:'/message',
                name:'message',
                component:()=>import('../views/message/index.vue')
            },
            {
                path:'/companydepartment',
                name:'companydepartment',
                component:()=>import('../views/companydepartment/index.vue')
            },
            {
                path:'/company-cate-charts',
                name:'company-cate-charts',
                component:()=>import('../views/company-cate-charts/index.vue')
            },
            {
                path:'/cate-to-company',
                name:'cate-to-company',
                component:()=>import('../views/cate-to-company/index.vue')
            },{
                path:'/article-type',
                name:'article-type',
                component:()=>import('../views/article-type/index.vue') 
            },{
                path:'/material',
                name:'material',
                component:()=>import('../views/material/index.vue')
            }
        ],
    },
    {
        path: "/login",
        name: "view-login",
        component: Login,
    },
    {
        path:"/big-cate",
        name:"big-cate",
        component: () =>import('../views/big-cate/index.vue')
    },
    {
        path: "*",
        name: "view-404",
        component: () =>
            import(/* webpackChunkName: 'view-404' */ "../views/404.vue"),
    }
];

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    if (to.path === "/rfm-detail") {
        if (store.state.buttons.findIndex(item => item.path === "rfm-detail") >= 0) {
            next();
        } else {
            Message.error("您没有该页面访问权限");
        }
    } else if (to.path === "/rfm-setup") {
        if (store.state.buttons.findIndex(item => item.path === "rfm-setup") >= 0) {
            next();
        } else {
            Message.error("您没有该页面访问权限");
        }
    } else {
        if (to.path !== '/login' && !store.state.token) {
            next('/login?type=' + (store.state.type == 1 ? '' : store.state.type == 2 ? 'company' : store.state.type == 6 ? 'masses' : ''));
        } else {
            next()
        }
    }
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

export default router;
