// Element UI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'


// Echarts
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart, BarChart, LineChart } from 'echarts/charts'
import { GridComponent, TooltipComponent, TitleComponent, LegendComponent, DataZoomComponent, BrushComponent, ToolboxComponent } from 'echarts/components'
use([CanvasRenderer, PieChart, BarChart, LineChart, GridComponent, TooltipComponent, TitleComponent, LegendComponent, DataZoomComponent, BrushComponent, ToolboxComponent])

export default {
    install(Vue) {
        Vue.use(ElementUI,{size:'small',zIndex:'2000'})
        Vue.component('v-chart', ECharts)
    }
}