<template>
    <el-dialog class="LPreviewImage" title="图片预览" :visible.sync="dialogVisible" width="1000px" :before-close="close">
        <div class="flex jus-c">
            <el-image :src="url"></el-image>
            <!-- <img :src="url" alt=""> -->
        </div>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    name:'preview-dialog',
    data(){
        return{
            dialogVisible:false,
        }
    },
    props:{
        url:String
    },
    methods:{
        setImg(url){
            this.url = url
        },
        show(){
            this.dialogVisible = true
        },
        close(){
            this.dialogVisible = false
        }
    },
}
</script>
<style>
    .LPreviewImage .el-image__error{
        height: 200px;
        width: 200px;
    }
    .flex{
        display: flex;
    }
    .jus-c{
        justify-content: center;
    }
</style>