<template>
    <el-button type="primary" @click="download">
        <slot></slot>
    </el-button>
</template>
<script>
export default{
    name:'zero-download',
    props:{
        url:String
    },
    methods:{
        download(){
            let a = document.createElement('a')
            a.setAttribute('download', '')
            console.log(this.url)
            a.setAttribute('href', this.url)
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a);
        }
    }
}
</script>